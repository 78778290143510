





























import Vue from "vue";
export default Vue.extend({
  name: "myDialog",
  props: {
    modalAppendTo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "编辑",
    },
    height: {
      type: Number,
      default: 30,
    },
    width: {
      type: Number,
      default: 25,
    },
    headerShow: {
      type: Boolean,
      default: true,
    },
    showFull: {
      type: Boolean,
      default: false,
    },
    contId: {
      type: String,
      default: "",
    },
  },
  beforeDestroy() {
    if (this.modalAppendTo) {
      if (document.getElementById(this.modalAppendTo)) {
        (document.getElementById(this.modalAppendTo) as Element).removeChild(
          this.$refs.myDialog as Element
        );
      }
    } else {
      document.body.removeChild(this.$refs.myDialog as Element);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.modalAppendTo) {
        (document.getElementById(this.modalAppendTo) as Element).appendChild(
          this.$refs.myDialog as Element
        );
      } else {
        document.body.appendChild(this.$refs.myDialog as Element);
      }
    });
  },
  methods: {
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    fullFn() {
      if (!this.$refs.diaCont) {
        return;
      }

      if (!this.isFullScreen(this.$refs.diaCont)) {
        this.launchFullScreen(this.$refs.diaCont); // 单独元素显示全屏
      } else {
        this.exitFullscreen(this.$refs.diaCont);
      }
    },
    isFullScreen(element) {
      return !!(
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      );
    },
    launchFullScreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        this.$message.warning("当前浏览器不支持部分全屏！");
      }
    },
    //退出全屏
    exitFullscreen(element) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
  },
});
