<template>
  <div class="treeScrollbarBox">
    <el-popover
      popper-class="treeScroll"
      :placement="placement"
      :disabled="disabled"
      width="100%"
      trigger="click"
      v-model="visible"
    >
      <el-input
        class="treeInputFilter"
        placeholder="输入关键字进行过滤"
        v-model="filterText"
      >
      </el-input>
      <el-scrollbar>
        <el-tree
          ref="manageTree"
          :data="list"
          :props="defaultProps"
          :node-key="keyCode"
          :highlight-current="false"
          :default-expanded-keys="expandedList"
          :default-checked-keys="[checkLabel]"
          accordion
          @node-click="handleNodeClick($event, false)"
          :default-expand-all="expandAll"
          :filter-node-method="filterNode"
          :render-content="renderContent"
        ></el-tree>
      </el-scrollbar>
      <div
        slot="reference"
        :class="['treeDiv', { unActive: disabled }]"
        :title="showTreeLable"
        @mouseleave="mouseleaveFn"
        @mouseenter="mouseenterFn"
      >
        <span v-if="!showTreeLable" class="placeholder">{{ placeholder }}</span>
        <span v-else>{{ showTreeLable }}</span>
        <div class="down">
          <i
            :class="[visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            v-if="show"
          ></i>
          <i
            class="el-icon-circle-close"
            v-if="!show"
            @click.stop="clearTreeLableFn"
          ></i>
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    keyCode: {
      type: String,
      default: "id",
    },
    department: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deptType: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入管理单位",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expandAll: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    //开启禁用
    disabledFlag: {
      type: Boolean,
      default: false,
    },
  },
  model: { prop: "department", event: "update:department" },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      showTreeLable: "",
      childrenList: [],
      checkLabel: this.department,
      show: true,
      visible: false,
      expandedList: [],
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.manageTree.filter(val);
    },
    checkLabel(val) {
      if (this.department != val) {
        this.$emit("update:department", val.toString());
      }
    },
    list(val) {
      let choosePark = this.getObjByTree(val, this.checkLabel);
      this.getDefaultExtent(this.list, this.checkLabel);
      if (choosePark) {
        this.handleNodeClick(choosePark, true);
      }
    },
    department(val) {
      this.checkLabel = val;
      if (val) {
        let choosePark = this.getObjByTree(this.list, this.checkLabel);
        if (choosePark) {
          this.getDefaultExtent(this.list, this.checkLabel);
          this.handleNodeClick(choosePark, true);
        }
      } else {
        this.showTreeLable = "";
      }
    },
  },
  methods: {
    renderContent(h, { node, data, store }) {
      return data.disabled && this.disabledFlag ? (
        <span class="el-tree-node__label tree_disabled_node__label">
          {node.name}
        </span>
      ) : (
        <span class="el-tree-node__label ">{node.name}</span>
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    mouseleaveFn() {
      this.show = true;
    },
    mouseenterFn() {
      if (this.showTreeLable && !this.disabled) {
        this.show = false;
      }
    },
    clearTreeLableFn() {
      this.checkLabel = "";
      this.$emit("handleNodeClick", null, true);
    },
    handleNodeClick(data, flag = false) {
      if (
        data.disabled &&
        this.disabledFlag &&
        data[this.keyCode] != this.department
      ) {
        return;
      }
      if (this.deptType) {
        //满足deptType的下面子集都可以点击
        if (data.deptType == this.deptType) {
          this.showTreeLable = data.name;
          this.checkLabel = data[this.keyCode];
          this.visible = false;
          this.$emit(
            "handleNodeClick",
            data,
            typeof flag == "boolean" ? !flag : true
          );

          if (!this.parkDict(data[this.keyCode], this.childrenList)) {
            this.childrenList = [data] || [];
          }
        } else {
          // if (this.childrenList.length == 0) {
          let perentTree = this.getRensTree(
            this.list,
            this.deptType,
            data[this.keyCode]
          );
          if (perentTree) {
            this.childrenList = [perentTree];
          }
          //}

          if (this.parkDict(data[this.keyCode], this.childrenList)) {
            this.showTreeLable = data.name;
            this.checkLabel = data[this.keyCode];
            this.visible = false;
            this.$emit(
              "handleNodeClick",
              data,
              typeof flag == "boolean" ? !flag : true
            );
          }
        }
      } else {
        //没有设置deptType都可以返回
        this.showTreeLable = data.name;
        this.checkLabel = data[this.keyCode];
        this.visible = false;
        this.$emit(
          "handleNodeClick",
          data,
          typeof flag == "boolean" ? !flag : true
        );
      }
    },
    getDefaultExtent(data, id) {
      if (!data) return; // return; 中断执行
      for (let i in data) {
        let item = data[i];
        if (this.getObjByTree(item.children, id)) {
          this.expandedList.push(item[this.keyCode]);
          if (item.children && typeof item.children == "object") {
            this.getDefaultExtent(item.children, id);
          }
          break;
        }
      }
    },
    getRensTree(data, deptType, id) {
      let result = null;
      if (!data) return; // return; 中断执行
      for (let i in data) {
        if (result !== null) break;
        let item = data[i];
        if (
          item.deptType == parseInt(deptType) &&
          this.getObjByTree(item.children, id)
        ) {
          result = item;
          break;
        } else if (item.children && typeof item.children == "object") {
          result = this.getRensTree(item.children, deptType, id);
        }
      }
      return result;
    },
    getObjByTree(data, id) {
      let result = null;
      if (!data) return; // return; 中断执行
      for (let i in data) {
        if (result !== null) break;
        let item = data[i];
        if (item[this.keyCode] === parseInt(id)) {
          result = item;
          break;
        } else if (item[this.keyCode] === id) {
          result = item;
          break;
        } else if (item.children && typeof item.children == "object") {
          result = this.getObjByTree(item.children, id);
        }
      }
      return result;
    },
    parkDict: function (val, childrenList) {
      let str = "";
      let pid = val;
      childrenList.forEach((item) => {
        if (item.children && item.children.length) {
          if (!str) {
            str = this.parkName(item.children, pid);
            return str;
          } else {
            return str;
          }
        }
      });
      return str;
    },

    parkName: function (obj, pid) {
      let str = "";
      obj.forEach((item) => {
        if (item[this.keyCode] == pid) {
          str = item.name;
          return false;
        } else {
          if (!str) {
            if (item.children && item.children.length) {
              return (str = this.parkName(item.children, pid));
            }
          }
        }
      });
      return str;
    },
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _this = this;
    setTimeout(function () {
      let choosePark = _this.getObjByTree(_this.list, _this.checkLabel);
      if (choosePark) {
        _this.handleNodeClick(choosePark, true);
      }
    }, 1000);
  },
};
</script>
<style scoped lang="less">
.treeScrollbarBox {
  height: 2.96vh;
  width: 100%;
  position: relative;

  .treeDiv {
    width: 100%;
    height: 2.96vh;
    min-height: 2.96vh;
    line-height: 2.96vh;
    border: 0.05vw solid #dcdfe6;
    border-radius: 0.2vw;
    color: #606266;
    display: inline-block;
    font-size: 0.73vw;
    padding: 0 20px 0 10px;
    min-width: 0;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    // border-color: #999999;

    .placeholder {
      color: #c0c4cc;
    }
    .down {
      position: absolute;
      right: 0.5vw;
      top: 0;
      color: #c0c4cc;
    }
  }

  .treeDiv.unActive {
    cursor: no-drop;
    background: #f5f7fa;
    color: #c0c4cc;
  }
}
.treeInputFilter {
  width: 100%;
  height: 2.96vh;
  min-height: 2.96vh;
  line-height: 2.96vh;
  margin-bottom: 1vh;
  ::v-deep {
    .el-input__inner {
      height: 100%;
      width: 100%;
    }
  }
}
.treeScroll {
  .el-scrollbar {
    max-height: 30vh;
    height: auto;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    ::v-deep {
      .el-tree {
        .tree_disabled_node__label {
          cursor: not-allowed;
          opacity: 0.7;
        }
        // .el-tree-node:focus > .el-tree-node__content,
        // .el-tree-node__content:hover {
        //   color: #49bfc6;
        //   background: #4b4c4d;
        // }
        // .is-checked {
        //   .el-tree-node__content {
        //     color: #49bfc6;
        //     background: #c0c4cc;
        //   }
        // }
      }
    }
  }
}
</style>
