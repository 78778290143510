import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import authModule from "@/store/modules/auth";
import router from "@/router";
import { Des_encrypt } from "@/utils/pubkey";
import { login } from "@/api/layout/login";
import { userDetails } from "@/api/user";
import { Message } from "element-ui";

export interface IUserState {
  userInfo: Record<string, unknown>;
}

@Module({ name: "user", dynamic: true, namespaced: true, store })
class User extends VuexModule implements IUserState {
  public userInfo: Record<string, unknown> = {};
  @Mutation
  private SET_USERINFO(userInfo: Record<string, unknown>) {
    this.userInfo = userInfo;
  }
  @Action
  async Login(userInfo: {
    username: string;
    password: string;
    method: string | "PASSWORD";
  }) {
    const { username, password, method = "PASSWORD" } = userInfo;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const password_ = await Des_encrypt(password);
      login({ username: username, password: password_, method: method })
        .then(async (res) => {
          authModule.setToken({
            token: res.content.tokenValue,
            expires: Number(
              Math.floor(res.content.tokenTimeout / (60 * 60 * 24))
            ),
          });
          authModule.setLoginId({
            loginId: res.content.loginId,
            expires: Number(
              Math.floor(res.content.tokenTimeout / (60 * 60 * 24))
            ),
          });
          await this.getUserInfo(res.content.loginId);
          Message({
            message: "登录成功",
            type: "success",
            duration: 3 * 1000,
          });
          router.push("/");
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  @Action
  async getUserInfo(id: string) {
    return new Promise((resolve, reject) => {
      userDetails(id)
        .then((res) => {
          this.setUserInfo(res.content);
          authModule.setAuth(
            res.content.roles?.map((c: any) => {
              return c.role;
            })
          );
          authModule.setPermissions(
            res.content.permissions?.map((c: any) => {
              return c.permission;
            })
          );
          resolve(res.content);
        })
        .catch(() => {
          reject();
        });
    });
  }
  @Action
  setUserInfo(userInfo: Record<string, unknown>) {
    this.SET_USERINFO(userInfo);
  }

  @Action
  logout() {
    authModule.clearToken();
    router.push("/login");
  }
}
export default getModule(User);
