import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

export const login = (data: any): Promise<any> =>
  request.post("/api/auth/login", data);

export const logout = (data: any): Promise<any> =>
  request.put("/api/auth/logout", data);

export const changePassword = (id: string, data: any): Promise<any> =>
  request.put("/api/auth/changePassword/" + id, data);

export const resetPassword = (id: string, data: any): Promise<any> =>
  request.put("/api/auth/resetPassword/" + id, data);

export const isInitialPassword = (params: any): Promise<any> =>
  request.put("/api/auth/isInitialPassword", { params });
