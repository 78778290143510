













































import { Vue, Component } from "vue-property-decorator";
import userModule from "@/store/modules/user";
const myWindow = require("@/../public/config");
import editForm from "./editForm.vue";
@Component({
  name: "Header",
  components: {
    editForm,
  },
})
export default class Header extends Vue {
  private avatar = "https://joeschmoe.io/api/v1/random";
  private headImgUrl = require("@/assets/layout/logo.png");
  private titleName = myWindow.configObj.VUE_APP_SYSTEM_TITLE;
  private editFormVisible = false;
  private baseEditInfo = {};

  get userInfo() {
    return userModule.userInfo;
  }
  logout() {
    userModule.logout();
  }
  changePassword() {
    this.baseEditInfo = userModule.userInfo;
    this.editFormVisible = true;
  }
  closeFn() {
    this.editFormVisible = false;
  }
}
