import { Layout } from "@/components";
import Vue, { ComponentOptions, AsyncComponent } from "vue";
import { RedirectOption } from "vue-router";
import { getRedirect } from "@/utils";

type Component =
  | ComponentOptions<Vue>
  | typeof Vue
  | AsyncComponent
  | Promise<typeof import("*.vue")>;

interface IMeta {
  title?: string; // 设置该路由在侧边栏和面包屑中展示的名字 不设置时不展示
  icon?: string; // 设置该路由在左侧菜单展示的图标 element-ui的icon
  breadcrumbAll?: boolean; //(默认 true)如果设置为false，则整个面包屑不会显示 此时可以自己在页面中写自定义的面包屑
  breadcrumb?: boolean; // (默认 true)如果设置为false，该路由则不会在breadcrumb面包屑中显示
  breadcrumbTo?: boolean; // (默认 true)该路由对应的面包屑是否可以点击
  activeMenu?: string; // 指定要高亮的左侧边栏的菜单 在跳转菜单的子页面但是想高亮左侧边栏时非常有用
  auth?: string; // 权限点唯一标识key 不配置时默认都可见 配置时权限接口返回的数组中需包含该key才会展示
}

export interface IBaseRouter {
  path: string; // 路由
  name?: string; // 名称
  children?: IBaseRouter[]; // 子路由
  redirect?: RedirectOption; // 路由重定向
  meta?: IMeta;
  hidden?: boolean; // (默认 false)当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些子页面编辑页面/edit/1
  component?: Component; // 路由渲染的组件页面
  props?: boolean; // 当 props 设置为 true 时，route.params 将被设置为组件的 props。
}

const routes: Array<IBaseRouter> = [
  {
    path: "/",
    redirect: () => {
      return getRedirect();
    },
    hidden: true,
  },
  {
    path: "/login",
    name: "login",
    hidden: true,
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: Layout,
    meta: {
      title: "首页",
      icon: "el-icon-position",
    },
    redirect: "/home/index",
    children: [
      {
        path: "/home/index",
        name: "/home/index",
        hidden: true,
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          breadcrumb: false,
        },
      },
    ],
  },
  {
    path: "/checkingIn",
    name: "checkingIn",
    component: Layout,
    meta: {
      title: "考勤管理",
      icon: "el-icon-user",
      auth: "ROLE::ADMIN,ROLE::ATTENDANCE_ADMIN,ROLE::ATTENDANCE_LOCAL",
    },
    redirect: "/checkingIn/index",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/checkingInManage/index.vue"),
        meta: {
          title: "考勤管理",
        },
      },
      {
        path: "history",
        name: "history",
        component: () => import("@/views/checkingInManage/history/index.vue"),
        meta: {
          title: "历史数据",
        },
      },
    ],
  },
  {
    path: "/salary",
    name: "salary",
    component: Layout,
    meta: {
      title: "薪酬管理",
      icon: "el-icon-document",
      auth: "ROLE::ADMIN,ROLE::PAYROLL_ADMIN,ROLE::PAYROLL_LOCAL",
    },
    redirect: "/salary/index",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/salaryManage/salary/index.vue"),
        meta: {
          title: "薪酬档案",
        },
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("@/views/salaryManage/setting/index.vue"),
        meta: {
          title: "配置信息",
        },
      },
    ],
  },
  {
    path: "/relation",
    name: "relation",
    component: Layout,
    meta: {
      title: "薪酬核算",
      icon: "el-icon-coin",
      auth: "ROLE::ADMIN,ROLE::PAYROLL_ADMIN,ROLE::PAYROLL_LOCAL",
    },
    redirect: "/relation/index",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/salaryManage/relation/index.vue"),
        hidden: true,
        meta: {
          title: "薪酬核算",
          breadcrumb: false,
        },
      },
    ],
  },
  {
    path: "/source",
    name: "source",
    component: Layout,
    meta: {
      title: "超级管理员",
      icon: "el-icon-setting",
      auth: "ROLE::ADMIN",
    },
    redirect: "/source/index",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/source/userManage/index.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "group",
        name: "group",
        component: () => import("@/views/source/groupManage/index.vue"),
        meta: {
          title: "分组管理",
        },
      },
      {
        path: "department",
        name: "department",
        component: () => import("@/views/source/departmentManage/index.vue"),
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "systemSetting",
        name: "systemSetting",
        component: () => import("@/views/source/systemSetting/index.vue"),
        meta: {
          title: "系统配置",
        },
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    hidden: true,
    component: Layout,
    redirect: "/error/404",
    meta: {
      title: "错误页面",
      icon: "el-icon-warning-outline",
    },
    children: [
      {
        path: "404",
        name: "404",
        component: () => import("../views/error/NotFoundPage.vue"),
        meta: {
          title: "404",
          breadcrumbAll: false,
        },
      },
      {
        path: "403",
        name: "403",
        component: () => import("../views/error/ForbiddenPage.vue"),
        meta: {
          title: "403",
          breadcrumbAll: false,
        },
      },
      {
        path: "500",
        name: "500",
        component: () => import("../views/error/InternalServerErrorPage.vue"),
        meta: {
          title: "500",
          breadcrumbAll: false,
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/error/404",
    hidden: true,
  },
];

export default routes;
