import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取用户信息
export const userDetails = (id: any): Promise<any> =>
  request.get("/api/user/" + id);

//新增用户
export const userAdd = (data: any): Promise<any> =>
  request.post("/api/user", data);

//修改用户
export const userUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/user/" + id, data);

//用户列表
export const userList = (params: any): Promise<any> =>
  request.get("/api/user", { params });

//用户删除
export const userDelete = (id: any): Promise<any> =>
  request.delete("/api/user/" + id);

//用户列表通用接口
export const userQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  params_.name = "like." + params_.name; //eq等于，like模糊
  params_.username = "like." + params_.username; //eq等于，like模糊
  return request.get("/api/user/query", { params: params_ });
};
