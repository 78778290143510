import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./index.less";
import * as echarts from "echarts";
import Cookies from "js-cookie";
import myPlugin from "@/plugins";

import "@/styles/index.less"; // css

import authModule from "@/store/modules/auth";
// import locale from "element-ui/lib/locale/lang/en";

//公共组件
import myDialog from "@/components/common/myDialog.vue";
import treeScrollbar from "@/components/common/treeScrollbar.vue";
import treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
//挂载全局配置
// import { myWindow } from "../public/config";

//字典相关接口全局挂载
import { roleDicts, permissionDicts } from "@/api/system/dict";
import { getHunImg, deepCopy, btnAuth, dzDict, showTime, showNumber } from "@/utils";

// 国际化设置
// Vue.use(ElementUI, { locale });
Vue.use(ElementUI);
Vue.use(myPlugin);
Vue.prototype.$echarts = echarts;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$authModule = authModule;
Vue.config.productionTip = false;
Vue.prototype.roleDicts = roleDicts;
Vue.prototype.permissionDicts = permissionDicts;
Vue.prototype.$getHunImg = getHunImg;
Vue.prototype.$deepCopy = deepCopy;
Vue.prototype.$btnAuth = btnAuth;
Vue.prototype.$dzDict = dzDict;
Vue.prototype.$showTime = showTime;
Vue.prototype.$showNumber = showNumber;
// Vue.prototype.myWindow = myWindow;
Vue.component("treeselect", treeselect); 
Vue.component("my-dialog", myDialog);
Vue.component("tree-scrollbar", treeScrollbar);

function loadIcon() {
  const url = require("@/assets/layout/icon_logo.png");
  const lineIcon = document.getElementById("titleLink") as HTMLLinkElement;
  lineIcon.href = url;
}

//页面标签
(async function () {
  await loadIcon();
})();

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
