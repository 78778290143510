



















































import Vue from "vue";
import { changePassword } from "@/api/layout/login";
import { Des_encrypt } from "@/utils/pubkey";
import { userPassward } from "@/utils/validate";
export default Vue.extend({
  props: ["baseEditInfo"],
  data() {
    return {
      editFormInfo: {
        oldPassword: null, //旧密码
        newPassword: null, //新密码
      },
      editFormInfoRules: {
        oldPassword: [{ required: true, message: "请输入旧密码" }],
        newPassword: [
          { required: true, message: "请输入新密码" },
          { validator: userPassward, trigger: ["blur"] },
        ],
      },
    };
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      obj.oldPassword = await Des_encrypt(obj.oldPassword);
      obj.newPassword = await Des_encrypt(obj.newPassword);
      if (this.baseEditInfo.id) {
        changePassword(this.baseEditInfo.id, obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
