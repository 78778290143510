let myWindow = {
  configObj: {
    //是否为DEV模式,打包是改为false
    VUE_IS_DEV: true,
    //打包后的文件目录名称，打包后不可以再修改
    VUE_PUBLIC_PATH: "salaryAccount",
    //系统名称
    VUE_APP_SYSTEM_TITLE: "薪酬核算系统",

    // api
    VUE_APP_BASE_API: "/dev-api",
    // 后台网关地址
    VUE_APP_SERVER_GATEWAY_URL: "https://payroll.bornya.com",

    getVuePublicPath: function () {
      return this.VUE_IS_DEV ? "/" : "/" + this.VUE_PUBLIC_PATH;
    },
  },
};

module.exports = myWindow;
