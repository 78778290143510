import JSEncrypt from "jsencrypt";
import authModule from "@/store/modules/auth";

//加密
export const Des_encrypt = async function (word: string) {
  // eslint-disable-next-line prefer-const
  let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
  encryptor.setPublicKey(authModule.pubkey); // 设置公钥
  // 对需要加密的数据进行加密
  return encryptor.encrypt(word);
};
