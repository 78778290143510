import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Message } from "element-ui";
import { jumpLogin, downloadFile } from "@/utils";
import { Loading } from "element-ui";
import { ElLoadingComponent } from "element-ui/types/loading";
const myWindow = require("../../public/config");
// import vm from "@/main";
import authModule from "@/store/modules/auth";

let loadingInstance: ElLoadingComponent | null = null;
let requestNum = 0;
const whiteList = [
  "/attendance/getAttendanceList",
  "department/SynchronousDepartment",
  "member/synchronizationMemberinfo",
];
const blobList = [
  "excel_template",
  "attendance/downloadAttendanceExcel",
  "/export",
  "/initSalaryBatch",
  "/changeSalaryBatch",
];

const addLoading = () => {
  // 增加loading 如果pending请求数量等于1，弹出loading, 防止重复弹出
  requestNum++;
  if (requestNum == 1) {
    loadingInstance = Loading.service({
      text: "正在努力加载中....",
      background: "rgba(0, 0, 0, 0)",
    });
  }
};

const cancelLoading = () => {
  // 取消loading 如果pending请求数量等于0，关闭loading
  requestNum--;
  if (requestNum === 0) loadingInstance?.close();
};

export const createAxiosByinterceptors = (
  config?: AxiosRequestConfig
): AxiosInstance => {
  const instance = axios.create({
    timeout: 40000,
    withCredentials: true,
    ...config,
  });

  // 添加请求拦截器
  instance.interceptors.request.use(
    async function (config: any) {
      // 在发送请求之前做些什么
      config.baseURL =
        process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "";
      const { loading = true } = config;
      const token = await authModule.getToken();
      if (token) {
        // config.headers.Authorization = token;
        config.headers.TOKEN = token;
      }

      if (
        blobList.findIndex((c) => config.url.indexOf(c) != -1) != -1 &&
        config.method == "get"
      ) {
        config.responseType = "blob";
      }

      if (whiteList.findIndex((c) => config.url.indexOf(c) != -1) != -1) {
        delete config.timeout;
      }

      if (
        loading &&
        whiteList.findIndex((c) => config.url.indexOf(c) != -1) == -1
      )
        addLoading();
      return config;
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );

  // 添加响应拦截器
  instance.interceptors.response.use(
    function (response) {
      // 对响应数据做点什么
      const { loading = true } = response.config;
      if (loading) cancelLoading();
      const { code, content, message, status } = response.data;
      // config设置responseType为blob 处理文件下载
      if (response.data instanceof Blob) {
        // return downloadFile(response);
        return response.data;
      } else if (
        typeof response.data == "string" &&
        response.data.indexOf("PK") == 0
      ) {
        return response.data;
      } else {
        if (code === 200 || status.toLowerCase() == "success")
          return response.data;
        else if (code === 401 || code === 104) {
          jumpLogin();
        } else {
          Message.error(message);
          return Promise.reject(response.data);
        }
      }
    },
    function (error) {
      // 对响应错误做点什么
      // const { loading = true } = error.config;
      // if (loading) cancelLoading();
      cancelLoading();
      if (error.response) {
        if (error.response.status === 401) {
          jumpLogin();
        }
      }
      Message.error(error?.response?.data?.message || "服务端异常");
      return Promise.reject(error);
    }
  );
  return instance;
};
