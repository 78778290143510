import { Message } from "element-ui";
import { AxiosResponse } from "axios";
import vm from "@/main";
import { IBaseRouter } from "@/router/config";
import authModule from "@/store/modules/auth";

/**
 *   跳转登录
 */
export const jumpLogin = () => {
  authModule.clearToken();
  vm.$router.push(`/login?redirect=${encodeURIComponent(vm.$route.fullPath)}`);
};

/**
 * 下载文件
 * @param response
 * @returns
 */
export const downloadFile = (response: AxiosResponse) => {
  console.log("response.data.type:", response.data.type);
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      try {
        console.log("result:", this.result);
        const jsonData = JSON.parse((this as any).result); // 成功 说明是普通对象数据
        if (jsonData?.code !== 200) {
          Message.error(jsonData?.message ?? "请求失败");
          reject(jsonData);
        }
      } catch (err) {
        // 解析成对象失败，说明是正常的文件流
        const blob = new Blob([response.data]);
        // 本地保存文件
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const filename = response?.headers?.["content-disposition"]
          ?.split("filename*=")?.[1]
          ?.substr(7);
        link.setAttribute("download", decodeURI(filename));
        document.body.appendChild(link);
        link.click();
        resolve(response.data);
      }
    };
    fileReader.readAsText(response.data);
  });
};

/**
 * 判断路由有无权限  路由无auth配置时默认都有
 * @param route
 * @param auths
 * @returns
 */
export const hasAuth = (route: IBaseRouter, auths: Array<string> | null) => {
  if (route?.meta?.auth) {
    const metaAuthArry = route?.meta?.auth.split(",");
    const newArr2 = metaAuthArry.filter((item) => {
      return auths.includes(item);
    });
    return newArr2.length > 0;
  } else {
    return true;
  }
};

/**
 * 递归过滤出有权限的路由
 * @param routes
 * @returns
 */
export const filterAuthRoutes = (routes: Array<IBaseRouter>) => {
  const authRoutes: Array<IBaseRouter> = [];
  const auths = authModule.auths;
  routes.forEach((route) => {
    if (hasAuth(route, auths)) {
      let children = undefined;
      if (route?.children) {
        children = filterAuthRoutes(route.children);
      }
      authRoutes.push({ ...route, children });
    }
  });
  return authRoutes;
};

/**
 * 获取文件路径
 * @param val
 * @param baseUrl
 * @param t_
 * @returns
 */
export const getHunImg = (val: string, baseUrl = "", t_ = "_t") => {
  if (val) {
    const ind_ = val.lastIndexOf(".");
    return (
      (val.includes("http") ? "" : baseUrl) +
      val.substring(0, ind_) +
      t_ +
      val.substring(ind_)
    );
  } else {
    return "";
  }
};

/**
 * 深度拷贝
 * @param obj
 * @returns
 */
export const deepCopy = (obj: any) => {
  if (
    obj &&
    typeof obj === "object" &&
    Object.prototype.toString.call(obj) === "[object Array]"
  ) {
    //数组
    const result = [];
    for (let i = 0; i < obj.length; i++) {
      result.push(deepCopy(obj[i]));
    }
    return result;
  } else if (obj && typeof obj === "object") {
    //对象
    const result = {};
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      result[keys[i]] = deepCopy(obj[keys[i]]);
    }
    return result;
  } else {
    return obj;
  }
};

export const btnAuth = (permission: string) => {
  const permissions = authModule.permissions;
  if (!permissions?.includes(permission as string)) {
    return false;
  } else {
    return true;
  }
};

export const dzDict = (
  val: any,
  strList: Array<any>,
  key = "dictValue",
  label = "dictLabel",
  flag = false
) => {
  if (!val) return "";
  if (!flag) {
    let arr = [];
    arr = strList.filter((item) => {
      return item[key] == val;
    });
    if (arr.length) {
      return arr[0][label];
    } else {
      return "";
    }
  } else {
    const valList_ = val;
    let arr = [];
    arr = strList.filter((item) => valList_.includes(item[key].toString()));
    if (arr.length > 0) {
      return arr
        .map((item) => {
          return item[label];
        })
        .join("、");
    } else {
      return "";
    }
  }
};

/**
 * @param {string} dateStr
 * @returns {string}
 */
export const showTime = (dateStr: string, flag = false, symbol = "-") => {
  if (dateStr) {
    const dateStrTo = new Date(dateStr);
    return (
      dateStrTo.getFullYear() +
      symbol +
      (dateStrTo.getMonth() + 1 < 10
        ? "0" + (dateStrTo.getMonth() + 1)
        : dateStrTo.getMonth() + 1) +
      symbol +
      (dateStrTo.getDate() < 10
        ? "0" + dateStrTo.getDate()
        : dateStrTo.getDate()) +
      (flag
        ? " " +
          (dateStrTo.getHours() < 10
            ? "0" + dateStrTo.getHours()
            : dateStrTo.getHours()) +
          ":" +
          (dateStrTo.getMinutes() < 10
            ? "0" + dateStrTo.getMinutes()
            : dateStrTo.getMinutes()) +
          ":" +
          (dateStrTo.getSeconds() < 10
            ? "0" + dateStrTo.getSeconds()
            : dateStrTo.getSeconds())
        : "")
    );
  } else {
    return "";
  }
};

export const showNumber = (val: any, float = 2) => {
  if (val) {
    if ((val + "").indexOf(".") == -1) {
      return val;
    } else {
      return Number(val).toFixed(float);
    }
  } else {
    return 0;
  }
};

export const getRedirect = () => {
  // const auths = authModule.auths;
  // const auths_ = "ROLE::PAYROLL_ADMIN,ROLE::PAYROLL_LOCAL";
  // const auths2_ = "ROLE::ATTENDANCE_ADMIN,ROLE::ATTENDANCE_LOCAL";

  // if (
  //   auths.filter((c) => auths_.indexOf(c) != -1).length > 0 &&
  //   auths.filter((c) => auths2_.indexOf(c) != -1).length == 0
  // ) {
  //   return "/salary";
  // } else {
  //   return "/checkingIn";
  // }
  return "/home";
};
