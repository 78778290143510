/**
 * @param {string} value
 * @returns {Boolean}
 */
export const validatorEmail = (rule: any, value: any, callback: any) => {
  if (value === "" || value == null) {
    callback();
  } else if (
    !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
  ) {
    callback(new Error("邮箱格式错误"));
  } else {
    callback();
  }
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
export const validatorPhone = (rule: any, value: any, callback: any) => {
  if (value === "" || value == null) {
    callback();
  } else if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(value)) {
    callback(new Error("手机号格式错误"));
  } else {
    callback();
  }
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
export const validatorPassword = (rule: any, value: any, callback: any) => {
  if (value === "" || value == null) {
    callback();
  } else if (
    !/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*?])\S*$/.test(
      value
    )
  ) {
    callback(
      new Error("密码最少6位(包含大、小写字母、数字和特殊字符(!@#$%^&*?))")
    );
  } else {
    callback();
  }
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
//正数（两位,包含0）
export const positiveNumber = (rule: any, value: any, callback: any) => {
  const reg = /^[+]?(([0-9]\d*))(\.\d{1,2})?$/g;
  if (value === "" || value == null) {
    callback();
  } else if (!reg.test(value)) {
    if (rule.max) {
      if (value > rule.max) {
        callback(new Error("请输入不大于" + rule.max + "正数(保留2位小数)"));
      } else {
        callback(new Error("请输入正数(保留2位小数)"));
      }
    } else {
      callback(new Error("请输入正数(保留2位小数)"));
    }
  } else {
    if (rule.max) {
      if (value > rule.max) {
        callback(new Error("请输入不大于" + rule.max + "正数(保留2位小数)"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
//用户名限制
export const userName = (rule: any, value: any, callback: any) => {
  const reg = /^[a-zA-Z0-9_]{5,18}$/g;
  if (value === "" || value == null) {
    callback();
  } else if (!reg.test(value)) {
    callback(new Error("包含大写字母、小写字母、数字或下划线,长度5-18位"));
  } else {
    callback();
  }
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
//密码强度限制
export const userPassward = (rule: any, value: any, callback: any) => {
  const reg =
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{6,18}$/g;
  if (value === "" || value == null) {
    callback();
  } else if (!reg.test(value)) {
    callback(
      new Error(
        "包含大写字母、小写字母、数字和特殊字符(_!@#$%^&*`~()-+=)中的至少三种,长度为6-18位"
      )
    );
  } else {
    callback();
  }
};
