import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取角色
export const roleDicts = (params: any): Promise<any> =>
  request.get("/api/role", params);

//获取权限
export const permissionDicts = (params: any): Promise<any> =>
  request.get("/api/permission", params);

export const genderList = ["男", "女"];

export const fieldTypeList = ["增项", "减项", "仅参考字段"];

export const fieldGroupList = ["增项", "减项", "单独展示"];
export const salaryStatusList = [
  { name: "未定薪", value: "NOT_INIT" },
  { name: "已定薪", value: "INIT" },
  { name: "已调薪", value: "CHANGE" },
  { name: "调薪未生效", value: "NOT_EFFECTIVE" },
];
export const userStatusList = [
  { name: "在职", value: "isNull" },
  { name: "离职", value: "isNotNull" },
];

export const reportList = [
  { name: "0", value: "false" },
  { name: "非0", value: "true" },
];
