import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import Cookies from "js-cookie";
const tokenName = "vue_salary_account_token";
const sessionName = "vue_salary_account_session";
const loginIdName = "vue_salary_account_loginId";

export interface IUserState {
  auths: Array<string>;
  token: string;
  pubkey: string;
  permissions: Array<string>;
}

@Module({ name: "auth", dynamic: true, namespaced: true, store })
class Auth extends VuexModule implements IUserState {
  public auths: Array<string> = []; //权限
  public token: string = Cookies.get(tokenName) || ""; //token
  public pubkey: string = Cookies.get(sessionName) || ""; //公钥
  public loginId: string = Cookies.get(loginIdName) || ""; //loginId
  public permissions: Array<string> = []; //按钮权限

  @Mutation
  SET_AUTHS(auths: Array<string>) {
    this.auths = auths;
  }

  @Mutation
  SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  SET_PUBKEY(pubkey: string) {
    this.pubkey = pubkey;
  }

  @Mutation
  SET_LOGINID(loginId: string) {
    this.loginId = loginId;
  }

  @Mutation
  SET_PERMISSIONS(permissions: Array<string>) {
    this.permissions = permissions;
  }

  //权限
  @Action
  async getAuth() {
    return this.auths;
  }

  @Action
  setAuth(auths: string[]) {
    this.SET_AUTHS(auths);
  }

  @Action
  clearAuth() {
    this.SET_AUTHS([]);
  }

  //token
  @Action
  setToken({ token = "", expires = 30 }) {
    Cookies.set(tokenName, token, { expires: expires });
  }
  @Action
  getToken() {
    return Cookies.get(tokenName);
  }
  @Action
  clearToken() {
    this.clearAuth();
    this.SET_TOKEN("");
    Cookies.remove(tokenName);
  }

  //公钥

  @Action
  setPubkey({ pubkey = "", expires = 30 }) {
    Cookies.set(sessionName, pubkey, { expires: expires });
    this.SET_PUBKEY(pubkey);
  }

  @Action
  getPubkey() {
    return this.pubkey || Cookies.get(sessionName);
  }

  //用户

  @Action
  setLoginId({ loginId = "", expires = 30 }) {
    Cookies.set(loginIdName, loginId, { expires: expires });
    this.SET_LOGINID(loginId);
  }

  @Action
  getLoginId() {
    return this.loginId || Cookies.get(loginIdName);
  }

  //按钮权限
  @Action
  async getPermissions() {
    return this.permissions;
  }

  @Action
  setPermissions(permissions: string[]) {
    this.SET_PERMISSIONS(permissions);
  }

  @Action
  clearPermissions() {
    this.SET_PERMISSIONS([]);
  }
}

export default getModule(Auth);
